.main-banner {
    position: relative;
    z-index: 99;
    min-height: 100vh;
    padding-bottom: 200px;

    .banner-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 137px;
        z-index: -1;
    }

    .banner-content {
        max-width: 546px;
        width: 100%;
        margin: 0 auto;
        padding-top: 147px;

        .timermain {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            display: flex;
            width: 100%;
            padding: 20px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;

            .timerleft {

                .timerhead {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                }

                .timerpara {
                    color: #B6B6B6;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

            .timerended {
                color: #FFF;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
            }

            .timerspans {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .maintime {
                    width: 54.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;

                    .innertimer {
                        background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #000;
                        display: flex;
                        padding: 11px 5px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;

                        .innertimertext {
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 110%;
                        }
                    }

                    .innertimepara {
                        color: #FFF;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }
        }

        .upper-content {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ;
            padding: 20px;
            margin-bottom: 20px;

            .left {
                display: flex;
                align-items: center;
                gap: 11px;

                .text {
                    h6 {
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                        margin-bottom: 5px;
                    }

                    p {
                        color: #717171;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                h6 {
                    color: #FFF;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    margin-bottom: 8px;
                    text-align: end;
                }

                p {
                    color: #28E664;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    text-align: end;
                }

                .red {
                    color: #f41e5e;
                }
            }
        }

        .bottom-content {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            padding: 20px;

            .main-head {
                color: #FFF;
                text-align: start;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 15px;
            }

            .parent-box {
                position: relative;

                .swap-arrow {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .inner-box {
                border: 1px solid #FFF;
                background: #000;
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .leftiii {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 84px;
                    width: 100%;
                    border: 1px solid #FFF;
                    background: #000;
                    padding: 10px;

                    h6 {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 110px;
                    width: 100%;

                    .dropdown {
                        width: 100%;

                        .dropdown-toggle {
                            border: 1px solid #FFF;
                            background: #000;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;

                            // &::after {
                            //     display: none;
                            // }
                        }

                        .dropdown-menu {
                            border: 1px solid #FFF;
                            background: #000;
                            border-radius: 0px !important;
                            padding: 0px;
                            min-width: 90px;
                            width: 100%;

                            a {
                                color: #fff;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                                letter-spacing: 0.1px;
                                padding: 15px 9px;

                                &:hover {
                                    background: rgba(255, 255, 255, 0.05) !important;
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 5px;

                                }
                            }
                        }
                    }

                    .text {
                        h6 {
                            color: #fff;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }

                .right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    flex-direction: column;
                    h6 {
                        color: #FFF;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 100%;
                        text-align: right;
                    }

                    // p{
                    //     color: rgba(255, 255, 255, 0.20);
                    //     font-size: 28px;
                    //     font-weight: 700;
                    //     line-height: 120%;
                    //     text-align: end;
                    // }
                    input {
                        color: #FFF;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        text-align: end;
                        background-color: transparent;
                        border: none;
                        max-width: 200px;
                        width: 100%;
                    

                        &::placeholder {
                            color: #FFF;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-align: end;
                        }
                    }
                }
            }

        }

        .bottom-text {
            margin-top: 20px;

            .inner-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                p {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                h6 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .inner-text-bold {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h6 {
                    color: #FFF;
                    text-align: right;
                    font-size: 25px;
                    font-weight: 700;
                }
            }
        }

        .btn-buy {
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            color: #2F2F2F;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: #050505;
            border: none;
            width: 100%;
        }
    }

}

.newClasss {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    display: flex;
    padding: 19px 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.suxvsvsyucsusc {
    width: 30px;
    height: 30px;
}

.custom-tabs{
    .nav-tabs{
        margin-bottom: 20px;
        border: 1px solid #FFF;
        justify-content: space-between;
        .nav-item{
            flex: 1;
            .nav-link{
                width: 100%;
                padding: 20px 10px;
                border: none;
                background-color: transparent;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                border-radius: 0;
            }
        }
         .nav-item.show .nav-link,  .nav-link.active{
            background-color: #fff;
            color: #000;
            border-radius: 0;
         }
    }
}

@media (max-width:600px){
    .main-banner .banner-content .timermain .timerleft .timerhead{
        font-size: 16px;
    }
    .main-banner .banner-content .timermain .timerleft .timerpara{
        font-size: 12px;
    }
    .main-banner .banner-content .timermain .timerspans .maintime .innertimer .innertimertext{
        font-size: 12px;
    }
    .main-banner .banner-content .timermain .timerspans .maintime .innertimer{
        padding: 0;
        width: 30px;
        height: 28px;
    }
    .main-banner .banner-content .timermain .timerspans .maintime .innertimepara{
        font-size: 7px;
    }
    .main-banner .banner-content .timermain .timerspans .maintime{
        width: 31px;
    }
    .main-banner .banner-content .timermain .timerspans{
        gap: 7px;
    }
    section.main-banner{
        padding-bottom: 200px;
    }
    .main-banner .banner-content .upper-content .right h6{
        font-size: 14px;
    }
}

@media (max-width:600px){
    .main-banner .material-textfield label{
        padding: 0 0rem 0 0.3rem !important;
    }
}

.right-text-content{
    display: flex;
    align-items: center;
    gap: 10px;
    .inner-left{
        h5{
            background: rgba(40, 230, 100, 0.20);
            padding: 4px 6px;
            color: #28E664;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            margin-bottom: 6px;
        }
        .special-para{
            color: #B6B6B6 !important;
            text-align: center !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important; 
            letter-spacing: 0.1px !important;
        }
    }
    .inner-right{
        p{
            color: #B6B6B6 !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important; 
            letter-spacing: 0.1px !important;
            margin-top: 8px !important;
            text-decoration: line-through;
        }
    }
}


.buyjustdop{
     .dropdown .dropdown-menu a img{
        width: 18px !important;
        height: 18px !important;
        object-fit: cover;
     }
}