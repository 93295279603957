.login-screen {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding-bottom: 200px;

    .login-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        height: 137px;
    }

    .upper-logo {
        border-bottom: 1px solid #FFF;
        background: #000;
        padding: 16px 0px;
    }

    .set-flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
            color: #fff;
            &:hover{
                color: #fff; 
            }
        }
    }

    .timermain {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        display: flex;
        width: 100%;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        max-width: 542px;
        width: 100%;
        margin: 0 auto 25px;
        margin-top: 100px;

        .timerleft {

            .timerhead {
                color: #FFF;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
            }

            .timerpara {
                color: #B6B6B6;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .timerended {
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }

        .timerspans {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .maintime {
                width: 54.5px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5px;

                .innertimer {
                    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #000;
                    display: flex;
                    padding: 11px 5px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;

                    .innertimertext {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%;
                    }
                }

                .innertimepara {
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                }
            }
        }
    }

    .bottom-card {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        display: flex;
        padding: 35px 50px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 542px;
        width: 100%;
        margin: 0 auto;

        h6 {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            line-height: 110%;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .btn-continue {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 2px 0px 0px #FFF;
            display: flex;
            padding: 15px 40px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
        }
    }
}

.connectwallet-modal {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(4px);

    .modal-dialog {
        max-width: 500px;
    }
 

    .modal-content {
        border-radius: 0px;
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        padding: 20px;
    }

    .modal-header {
        border-radius: 0px;
        border: 1px solid #FFF;
        background: #000;
        padding: 15px;
        justify-content: space-between;

        .modal-title {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }

        .btn-close {
            background: url("../../assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            border: none;
            box-shadow: none;
            padding: 0;
            outline: none;
            opacity: unset;
            width: 24px;
            height: 24px;
            margin: 0;
        }
    }

    .modal-body {
        padding: 0px;

        .darktext {
            background: #080808;
            padding: 20px 10px 10px 20px;
            margin-top: 15px;
            margin-bottom: 30px;

            .scrolltext {
                height: 380px;
                overflow: auto;
                padding-right: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #2B2A2A;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #FFF;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #FFF;
                }

                .formarginleft{
                    margin-left: 30px;
                }

                .darkpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 117%;
                    margin-bottom: 14px;
                    .bold{
                        font-weight: 700;
                    }
                    .headingtext{
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 117%;
                    }
                    .formarginleft{
                        margin-left: 30px;
                    }
                }

                .darkparaupper {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 117%;
                    margin: 14px 0px;
                }
            }
        }

        .checkboxmain {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;
            .clear {
                clear: both;
              }
              
              .checkBox {
                display: block;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border: 3px solid rgba(255, 255, 255, 0);
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 0px 2px #FFF;
              }
              
              .checkBox div {
                width: 60px;
                height: 60px;
                background-color: #FFF;
                top: -52px;
                left: -52px;
                position: absolute;
                transform: rotateZ(45deg);
                z-index: 100;
              }
              
              .checkBox input[type=checkbox]:checked + div {
                left: -10px;
                top: -10px;
              }
              
              .checkBox input[type=checkbox] {
                position: absolute;
                left: 50px;
                visibility: hidden;
              }
              
              .transition {
                transition: 300ms ease;
              }

            .checkboxpara {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 117%;
            }
        }

        .continuebutton {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            margin-top: 25px;
        }

        .disablebutton {
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            color: #363636;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
            margin-top: 25px;
        }

        .twice-btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            gap: 15px;

            button {
                background-color: transparent;
                border: 1px solid #242424;
                background: #000;
                height: 130px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: 0.3s linear;
                gap: 13px;
                padding: 40px;

                h6 {
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                &:hover {
                    border: 1px solid #FFF;
                    background: #000;
                    box-shadow: 2px 2px 0px 0px #FFF;
                }
            }
        }

        .buy-modal {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 30px;

            p {
                color: #FFF;
                font-family: Space Mono;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                text-align: center;
                margin: 20px 0px 30px;

                span {
                    font-weight: 700;
                }
            }

            .btn-common {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 4px 4px 0px 0px #FFF;
                display: flex;
                padding: 19px 40px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                width: 100%;
            }

        }
    }
}

.buy-modal {
    .modal-dialog {
        max-width: 508px;
    }
}

@media (max-width:600px) {
    .login-screen .bottom-card {
        padding: 20px;
    }

    .login-screen .bottom-card h6 {
        font-size: 16px;
        text-align: center;
    }

    .scvtsvctsc {
        max-width: 123px;
        width: 100%;
    }

    .connectwallet-modal .modal-body .twice-btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .connectwallet-modal .modal-body .twice-btns button {
        padding: 30px;
    }

    .connectwallet-modal .modal-body {
        padding: 12px 0;
    }

    .login-screen .upper-logo {
        margin-left: 0;
        // text-align: center;
    }

    .login-screen .timermain .timerleft .timerhead {
        font-size: 16px;
    }

    .login-screen .timermain .timerleft .timerpara {
        font-size: 12px;
    }

    .login-screen .timermain .timerspans .maintime .innertimer .innertimertext {
        font-size: 12px;
    }

    .login-screen .timermain .timerspans .maintime .innertimer {
        padding: 0;
        width: 30px;
        height: 28px;
    }

    .login-screen .timermain .timerspans .maintime .innertimepara {
        font-size: 7px;
    }

    .login-screen .timermain .timerspans .maintime {
        width: 31px;
    }

    .login-screen .timermain .timerspans {
        gap: 7px;
    }

    section.login-screen {
        padding-bottom: 100px;
    }
}


.newClass {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    display: flex;
    padding: 15px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    width: 100%;
}
.nooreee{
    .modal-dialog{
        max-width: 674px!important;
    }
}