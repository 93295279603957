.dop_nft {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    padding: 20px;

    .parent-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 12px;
        .inner-card {
            border: 1px solid #FFF;
            background: #000;
            padding: 8px;

            .upper-img {
                border: 1px solid #FFF;
                background: #000;
                box-shadow: 2px 2px 0px 0px #FFF;
                width: 100%;
                height: 231px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .bottom-text {
                margin-top: 12px !important;
                .inner-text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 12px !important;

                    p {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }

                    h6 {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%;
                    }
                }
            }

            .select-nft {
                border: 1px solid #FFF;
                background: #000;
                padding: 4px 7px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img{
                    cursor: pointer;
                }

                .mid {
                    h6 {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }
        }
    }

    .btn-buy-nft {
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        padding: 19px;
        width: 100%;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin-top: 25px;
    }
    .select-drop{
        margin-bottom: 20px;
        .dropdown-toggle{
            border: 1px solid #FFF;
            background: #000;
            padding: 10px;
            gap: 8px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; 
            letter-spacing: 0.1px;
            width: 100%;
            &::after{
                display: none;
            }
            .twice{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .inner-flex{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                }
            }
        }
        .dropdown-menu{
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.05);
            width: 100%;
            a{
                border-bottom: 1px solid #F3F3F3;
                background: #0D0D0D;
                padding: 14px 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}

@media (max-width:600px){
    .dop_nft .parent-card{
        grid-template-columns: repeat(1, 1fr);
    }
    .dop_nft .parent-card .inner-card .upper-img{
        height: 334px;
    }
}

.buydopnft-modal{
    margin: 30px 0;
    .inner-text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        p{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
        }
        h6{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; 
        }
    }
}

.twice-btnsss{
    display: flex;
    justify-content: space-between;
    align-items: center;
    button{
        flex: 0 0 48%;
    }
    .btn-backkk{
        background: #050505;
        border: none;
        padding: 15px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;
    }
    .btn-proceed{
        border: 1px solid #FFF;
        background: #000;
        box-shadow: 4px 4px 0px 0px #FFF;
        padding: 15px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; 
        text-transform: uppercase;
    }
}

.buynft-modal2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h5{
        color: var(--secondary-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; 
        margin-top: 20px;
        max-width: 350px;
        span{
            font-weight: 700;
        }
    }
}

.sscscscscscsc{
    flex: 0 0 100% !important;
}


.btn-buy {
    display: flex;
            justify-content: center;
            align-items: center;
            color: #2F2F2F;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            background: #050505;
            border: none;
            width: 100%;
            padding: 19px;
            line-height: 100%;
            margin-top: 25px;
}


.dop_nft .select-drop .dropdown-menu{
    img{
        width: 26px !important;
        height: 26px !important;
        object-fit: cover;
    }
}