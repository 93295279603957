@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background: #000;
  height: 100vh;
  font-family: 'Space Mono', monospace;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.gscrxcsx {
  &:hover {
    background-color: transparent !important;
  }
}

.noora {
  color: #fff !important;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 100% !important;
  letter-spacing: 0.1px !important;
  margin-top: 25px !important;
}

@media (max-width:600px) {
  .noora {
    margin-top: 15px;
    font-size: 16px !important;
  }
}

@media (max-width:1024px) {
  .forhideee {
    display: none !important;
  }
}

section.main-banner {
  z-index: 9 !important;
}

.material-textfield {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  padding: 0 6.4rem 0 1.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  color: #343434;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #FFF;
  background: #000;
  border-radius: 0px;
  padding: 17px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;

  &::placeholder {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;
  }
}

.material-textfield input:focus+label {
  color: #FFF;
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width:768px) {
  .main-banner .banner-bg {
    display: none !important;
  }

  .main-banner .mblviewbanner-bg {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 99px;
    z-index: -1;
  }

  .login-screen .mblviewbanner-bg {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 99px;
    z-index: -1;
  }

  .login-screen .banner-bg {
    display: none !important;
  }

  .login-screen .login-bg {
    display: none !important;
  }
}


.mainLoader {
  //   -webkit-filter: blur(100px);
  // -moz-filter: blur(100px);
  // -ms-filter: blur(100px);
  // -o-filter: blur(100px);
  backdrop-filter: blur(100px);
  -moz-backdrop-filter: blur(100px) !important;
}


.service-modal {
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(3px);

  .modal-content {
    border-radius: 0px;
    border: 1px solid #fff;
    background: #000;
    padding: 20px 20px 30px 20px;
    box-shadow: 4px 4px 0px 0px #fff;
  }

  .modal-body {
    padding: 0;
  }

  .upper-div {
    border-radius: 0px;
    border: 1px solid #fff;
    background: #000;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    h6 {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
    }
  }

  .bottom-div {
    border: 1px solid #fff;
    border-radius: 0px;
    background: #000;
    padding: 20px;

    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 22.4px */
      margin-bottom: 10px;
    }

    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 19.6px */
    }
  }
}


.set-new-for-closed {
  .modal-dialog {
    max-width: 528px;
  }

  .modal-header {
    border: none;
    justify-content: center;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding: 15px;
  }

  .modal-content {
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    padding: 20px;
    border-radius: 0;
  }

  .modal-body {
    padding: 0;
  }

  p {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 35px 0;
  }

  a {
    padding: 19px 40px;
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 4px 4px 0px 0px #FFF;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}


.new-sectionforclosing {
  border-bottom: 1px solid #FFF;
  background: #000;
  padding: 16px 0;
  position: relative;
  z-index: 99;
  height: 100vh;
  .bg-img{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  
  }

  .top-navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
   
  }
}