.main-navbar {
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 30;
    width: 100%;
    border-bottom: 1px solid #FFF;
    background: #000;
    backdrop-filter: blur(12px);
    padding-bottom: 16px;

    ul {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nav-item {
            .nav-link {
                color: #FFF;
                font-size: 16px;
                font-weight: 400;
                line-height: 100%;
                padding: 0;
                padding-right: 40px;
                transition: 0.3s linear;

                &:hover {
                    font-weight: 700;
                }
            }
        }
    }

    .connect-btn {
        display: flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.015px;
        border: 1px solid #FFF;
        background-color: transparent;
    }

    .dissconnect-btn {
        border: 1px solid #FE0000;
        color: #FE0000;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.015px;
        display: flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }
}

@media (max-width:600px) {
    .offcanvas {
        width: 100% !important;
    }

    .main-navbar ul {
        position: static;
        transform: unset;
    }

    .main-navbar ul .nav-item .nav-link {
        padding: 15px 0;
        text-align: center;
    }

    .main-navbar .connect-btn {
        display: block;
        margin: 0 auto;
    }

    .navbar-collapse {
        border-radius: 15px;
        border: 1px solid #161516;
        background: rgba(17, 19, 22, 0.30);
        backdrop-filter: blur(3px);
        margin-top: 25px;
        padding: 15px;
    }

    .connectwallet-modal .modal-body .twice-btns button {
        padding: 30px 0;
    }
}


.offcanvas {
    background: #030303 !important;

    .offcanvas-header {
        padding: 16px 15px;
        border-bottom: 1px solid #FFF;
        background: #000;

        .btn-close {
            background: url("../../../assets/close-circle-new.svg") !important;
            background-size: contain !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            border: none !important;
            opacity: unset;
            padding: 0;
            width: 26px;
            height: 26px;
            margin: 0;
            box-shadow: none !important;
        }
    }

    .offcanvas-body {
        padding: 34px 0px 0px;
        a {
            padding: 20px 25px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            display: block;
            border-left: 6px solid transparent;

            &:hover {
                border-left: 6px solid #FFF;
                background: #080808;
            }

            &:focus {
                border-left: 6px solid #FFF;
                background: #080808;
            }

            &:active {
                border-left: 6px solid #FFF;
                background: #080808;
            }
        }

        .btnss{
            padding: 34px 20px;

        .connect-btn {
            display: flex;
            padding: 17px 15px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.015px;
            border: 1px solid #FFF;
            background-color: transparent;
            width: 100%;
        }
    
        .dissconnect-btn {
            border: 1px solid #FE0000;
            color: #FE0000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.015px;
            display: flex;
            padding: 17px 15px;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            width: 100%;
        }
    }
    }
}

.payyy{
    width: 50px !important;
    width: 50px !important;
    border-radius: 50% !important;
}